import { template as template_87d866ac6a5f43c491953d9e9c146c4b } from "@ember/template-compiler";
const FKText = template_87d866ac6a5f43c491953d9e9c146c4b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
