import { template as template_2665817a959d4c2fbf1d3c6dd0cd5dff } from "@ember/template-compiler";
const FKControlMenuContainer = template_2665817a959d4c2fbf1d3c6dd0cd5dff(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
