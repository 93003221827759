import { template as template_33579e6a15cf4b5a9b840d5d661e6cfe } from "@ember/template-compiler";
const FKLabel = template_33579e6a15cf4b5a9b840d5d661e6cfe(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
